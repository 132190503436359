import { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Fade, Flex } from '@chakra-ui/react';
import { ContestParticipant, LeaderboardStat } from 'models';

import LeaderboardInviteRow from './LeaderboardInviteRow';
import LeaderboardRow from './LeaderboardRow';
import MySquad from './MySquad';
import NoParticipants from './NoParticipants';
import NotStartedMessage from './NotStartedMessage';

type Props = {
  stat: LeaderboardStat;
  participant?: ContestParticipant;
  showLeaderboard?: boolean;
  showMySquad?: boolean;
  showInviteRow?: boolean;
  showNoParticipants?: boolean;
};

const Leaderboard: FC<Props> = ({
  stat,
  showLeaderboard = true,
  showMySquad = true,
  participant,
  showInviteRow = false,
  showNoParticipants = false,
}) => {
  const [searchParams] = useSearchParams();

  const allParticipants = stat.participants;
  const friends =
    stat.me && allParticipants.length <= 19
      ? [...allParticipants, stat.me].sort((a, b) => (a.place ?? 0) - (b.place ?? 0))
      : allParticipants;
  const participants = searchParams.get('friends') === 'true' ? friends : allParticipants;

  const myPlaceAmongFriends = participants.findIndex((u) => u.user.id === stat.me?.user.id) + 1;

  return (
    <>
      <Flex flexDirection="column" flex="1">
        {stat.me && showMySquad && (
          <MySquad myPlaceAmongFriends={myPlaceAmongFriends} stat={stat} contestStatus={stat.currentContest.status} />
        )}
        {showLeaderboard && participants.length > 0 && (
          <Flex flexDirection="column">
            {
              <>
                {participants.map((p, index) => (
                  <Fade key={`${p.id}-${index}`} in={true} unmountOnExit>
                    <LeaderboardRow position={index} participant={p} contest={stat.currentContest} />
                  </Fade>
                ))}
              </>
            }
          </Flex>
        )}
        {showInviteRow && (
          <Flex flexDirection="column">
            {searchParams.get('friends') === 'true' &&
              Array.from({ length: 2 }).map((_, index) => <LeaderboardInviteRow key={`invite-row-${index}`} />)}
          </Flex>
        )}
        {showNoParticipants && participants.length === 0 && (
          <Flex h="100%" flexDirection="column">
            <NoParticipants />
          </Flex>
        )}
        {stat.participants.length === 0 && stat.currentContest.status === 'locked' && <NotStartedMessage stat={stat} />}
      </Flex>
    </>
  );
};

export default Leaderboard;
